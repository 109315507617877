<template>
  <b-container class="mt-2">
    <h2>{{ algorythm ? algorythm.name : "" }}</h2>
    <b-form-select
      v-model="algorythm"
      :options="algorythms"
      placeholder="Выберите алгоритм"
      ><template #first>
        <b-form-select-option :value="null" disabled
          >-- Выберите алгоритм --</b-form-select-option
        >
      </template>
    </b-form-select>
    <div v-if="algorythm">
      <b-card
        header="Настройки алфавита"
        class="my-3"
        v-if="!algorythm.no_alphabet"
      >
        <b-form-group>
          <b-form-select
            v-model="alphabet"
            :options="alphabets"
            placeholder="Выберите алфавит"
            ><template #first>
              <b-form-select-option :value="null" disabled
                >-- Выберите алфавит --</b-form-select-option
              >
            </template></b-form-select
          ></b-form-group
        >
        <p v-if="alphabet">
          Выбранный алфавит содержит символы в следующем порядке:
          {{ alphabet.value }}
        </p>
      </b-card>
      <b-card
        header="Настройки ключевых параметров"
        v-if="algorythm.options.length"
        class="my-3"
      >
        <b-form-group v-for="(opt, i) in algorythm.options" :key="i">
          <div v-if="opt.type == 'matrix'">
            <b-input-group class="mb-2" size="sm" :prepend="opt.name">
              <b-form-input v-model.number="matrix_size"></b-form-input>
            </b-input-group>
            <b-input-group
              v-for="(i, ind) in keys[opt.name]"
              :key="ind"
              :id="ind"
            >
              <b-form-input
                v-for="(j, jind) in keys[opt.name][ind]"
                v-model.number="keys[opt.name][ind][jind]"
                :key="jind"
              ></b-form-input>
            </b-input-group>
            <b-form-text>{{ opt.description }}</b-form-text>
          </div>
          <div v-else-if="opt.type == 'cardano'">
            <b-input-group class="mb-2" size="sm" :prepend="opt.name">
              <b-form-input v-model.number="cardano_size[0]"></b-form-input>
              X
              <b-form-input v-model.number="cardano_size[1]"></b-form-input>
            </b-input-group>
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(i, ind) in keys[opt.name]" :key="ind" :id="ind">
                  <td
                    v-for="(j, jind) in keys[opt.name][ind]"
                    @click="
                      () => {
                        keys[opt.name][ind][jind] =
                          keys[opt.name][ind][jind] == 0 ? 1 : 0;
                        $forceUpdate();
                      }
                    "
                    :key="jind"
                    :class="keys[opt.name][ind][jind] == 1 ? 'bg-success' : ''"
                  >
                    {{ "\xa0" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <b-form-text>{{ opt.description }}</b-form-text>
          </div>
          <div v-else-if="opt.type == 'choice'">
            <b-form-select
              value-field="code"
              text-field="name"
              v-model="keys[opt.name]"
              :options="opt.choices"
            ></b-form-select>
          </div>
          <div v-else>
            <b-input-group size="sm" :prepend="opt.name">
              <b-form-input v-model.trim="keys[opt.name]"></b-form-input>
            </b-input-group>
            <b-form-text>{{ opt.description }}</b-form-text>
          </div>
        </b-form-group>
      </b-card>
      <b-card no-body>
        <b-tabs card fill>
          <b-tab title="Текст" active @click="file = null">
            <b-textarea v-model="text" placeholder="Введите текст"></b-textarea>
          </b-tab>
          <b-tab @click="text = ''" title="Файл"
            ><b-form-file
              accept=".txt"
              placeholder="Выберите файл или перетащите его сюда..."
              drop-placeholder="Перетащите файл сюда..."
              browse-text="Выбрать"
              v-model="file"
            ></b-form-file>
          </b-tab>
        </b-tabs>
        <b-checkbox v-model="to_file" class="mx-3 mb-3"
          >Записать результат в файл</b-checkbox
        >
      </b-card>
      <b-card class="mt-3" v-if="result" header="Результат">
        <p v-if="result">{{ result.result }}</p>
        <pre v-if="result">{{ result.info }}</pre>
      </b-card>
      <div class="mt-3">
        <b-button class="mr-3" variant="primary" @click="getResult('E')">{{
          buttons.encrypt
        }}</b-button>
        <b-button variant="warning" @click="getResult('D')">{{
          buttons.decrypt
        }}</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import http from "../http";

export default {
  data() {
    return {
      algorythms: [],
      alphabets: [],
      text: "",
      keys: {},
      matrix_size: 0,
      cardano_size: [6, 6],
      algorythm: null,
      alphabet: null,
      result: "",
      file: null,
      to_file: false,
      buttons: {
        encrypt: "Зашифровать",
        decrypt: "Расшифровать",
      },
    };
  },
  methods: {
    async getResult(action) {
      let data = {
        action: action,
        keys: JSON.stringify(this.keys),
        text: this.text,
        alphabet: this.alphabet?.id,
        algorythm: this.algorythm.id,
        f: this.file,
        return_file: this.to_file.toString(),
      };
      var formData = new FormData();
      for (let d in data) {
        formData.append(d, data[d] || "");
      }
      data = formData;
      if (!this.to_file) {
        var result = await axios.post("/api/algorythm/action/", data);
      } else {
        result = await axios.post("/api/algorythm/action/", data, {
          responseType: "blob",
        });
        var downloadUrl = URL.createObjectURL(result.data);
        var a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = "result.txt";
          document.body.appendChild(a);
          a.click();
        }
        return;
      }

      if (result.status != 200) {
        this.$bvModal.msgBoxOk(result.data, {
          title: "Ошибка",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      } else {
        this.result = result.data;
      }
    },
    // changeCardano(opt_name, row, col){
    //   if (keys[opt_name][row][col] == 0){
    //     keys[opt_name][row][col] = 1;
    //     var sim_col =
    //     keys[opt_name][row][col]
    //   } else if (keys[opt_name][row][col] == 1) {
    //     keys[opt_name][row][col] = 0;
    //   }
    //   $forceUpdate();
    // },
  },
  async beforeMount() {
    var alphabets = (await http.getList("Alphabet")).data;
    for (let a of alphabets) {
      this.alphabets.push({
        text: a.name,
        value: a,
      });
    }
    var algorythms = (await http.getList("Algorythm")).data;
    for (let a of algorythms) {
      this.algorythms.push({
        text: a.name,
        value: a,
      });
    }
    if (this.$route.query.algo) {
      this.algorythm =
        this.algorythms[
          this.algorythms.findIndex((v) => v.value.id == this.$route.query.algo)
        ].value;
    }
    if (this.$route.query.alphabet) {
      this.alphabet =
        this.alphabets[
          this.alphabets.findIndex(
            (v) => v.value.id == this.$route.query.alphabet
          )
        ].value;
    }
    this.matrix_size = 3;
  },
  watch: {
    algorythm() {
      this.keys = {};
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, algo: this.algorythm.id },
      });
      if (this.algorythm.type == "C") {
        this.buttons = {
          encrypt: "Зашифровать",
          decrypt: "Расшифровать",
        };
      } else if (this.algorythm.type == "S") {
        this.buttons = {
          encrypt: "Подписать",
          decrypt: "Проверить подпись",
        };
      } else {
        this.buttons = {
          encrypt: "Вычислить открытый ключ",
          decrypt: "Вычислить общий секретный ключ",
        };
      }
    },
    alphabet() {
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, alphabet: this.alphabet.id },
      });
    },
    matrix_size() {
      this.keys["M"] = [];
      for (let i = 0; i < this.matrix_size; i++) {
        this.keys["M"].push([]);
        for (let j = 0; j < this.matrix_size; j++) {
          this.keys["M"][i].push(0);
        }
      }
    },
    cardano_size() {
      this.keys["R"] = [];
      for (let i = 0; i < this.cardano_size[0]; i++) {
        this.keys["R"].push([]);
        for (let j = 0; j < this.cardano_size[1]; j++) {
          this.keys["R"][i].push(0);
        }
      }
    },
  },
};
</script>

<style>
#app {
  overflow: visible;
}
body {
  position: static !important;
}
.clickable {
  cursor: pointer;
}
</style>